export const httpMethods = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'] as const;
export const blobMIMETypes = ['application/pdf', 'text/csv'] as const;
export const FILE_SIZE_LIMIT = 200 * 1024 * 1024; // 200MB

export type HttpMethods = (typeof httpMethods)[number];
export type BlobMIMETypes = (typeof blobMIMETypes)[number];

export interface FetchConfig<RequestBody> {
  method?: HttpMethods;
  queryParams?: URLSearchParams;
  body?: RequestBody;
  options?: RequestInit;
  isMultiPart?: boolean;
}

function toUrl(path: string, queryParams?: URLSearchParams) {
  const prefix = path.startsWith('https://');
  const url = prefix ? new URL(path) : new URL(`${API_PATH}${path}`, BACKEND_HOST);
  if (queryParams) {
    url.search = queryParams.toString();
  }
  return url.toString();
}

function toFormData<T>(data: T) {
  if (data instanceof Object) {
    return Object.entries(data).reduce((formData, [key, value]) => {
      formData.append(key, value);
      return formData;
    }, new FormData());
  }
}

function toRequestBody<T>(data?: T, isMultiPart?: boolean) {
  if (!data) return undefined;

  return isMultiPart ? toFormData(data) : JSON.stringify(data);
}

export async function fetchApi<RequestBody, ResponseType>(
  endpoint: string,
  config: FetchConfig<RequestBody> = { method: 'GET' },
) {
  const url = toUrl(endpoint, config?.queryParams);
  const { file } = (config.body || {}) as Record<string, File>;

  if (config.isMultiPart && file instanceof File && file?.size >= FILE_SIZE_LIMIT) {
    throw new Error('FILE_EXCEEDS_SIZE_LIMIT');
  }

  const response = await fetch(url, {
    ...config.options,
    method: config.method,
    body: toRequestBody(config.body, config.isMultiPart),
    headers: {
      ...(!config.isMultiPart ? { 'Content-Type': 'application/json' } : {}),
      ...config.options?.headers,
    },
  });

  if (!response.ok) {
    const errorResponse = await response.json().catch(() => null);
    const error = errorResponse ? errorResponse : response.statusText;

    if (Array.isArray(error) && error.length > 0) throw error;

    throw new Error(error instanceof Object && error.detail ? error.detail : error);
  }

  const contentType = response.headers.get('Content-Type');

  if (!contentType) return undefined as ResponseType;

  if (contentType?.includes('application/json')) {
    return response.json() as Promise<ResponseType>;
  }

  if (blobMIMETypes.includes(contentType?.split(';')[0] as BlobMIMETypes)) {
    return response.blob() as Promise<ResponseType>;
  }

  throw new Error('Unsupported Content-Type');
}
